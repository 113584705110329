<template>
  <div>
    <CRow>
      <CCol sm="12">
        Mi Cuenta
      </CCol>
    </CRow>
  </div>
</template>

<script>
import roads from '../../services/roads';
import bot from '../../services/bot';
import store from '../../store'
import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: 'MyAccount',
  components: { },
  data: function () {
		return {
    }
  },
  mounted: async function() {
    this.loading();

    this.loaded();
  },
  methods: {
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
